<template>
  <div class="ccc">
    <TopHead></TopHead>
    <logoNav></logoNav>
    <!--页面主体-->
    <section class="main">
      <div class="container">
        <div class="stepbox five">
          <div class="line"></div>
          <div class="now"></div>
          <div class="step stepone ok">
            <span><i class="icon-stepone"></i></span>
            <p>1、选择票种</p>
          </div>
          <div class="step steptwo f_s ok">
            <span><i class="icon-steptwo"></i></span>
            <p>2、填写游客信息</p>
          </div>
          <div class="step stepthree f_s ok">
            <span><i class="icon-stepthree"></i></span>
            <p>3、确认支付</p>
          </div>
          <div class="step five f_s ok">
            <span><i class="icon-stepfive"></i></span>
            <p>4、购票成功</p>
          </div>
        </div>
        <div class="content payResult">
          <div class="orderinfo" v-if="$route.query.itineraryType == 2">
            <h1>
              <i></i>
              <p>预约单提交成功</p>
            </h1>
            <p><span class="price">等待审核</span></p>
            <p>预约单号：<em>{{$route.query.orderNumber}}</em></p>
            <p>
              <router-link :to="{path:'/reservation',query:{orderNumber:$route.query.orderNumber,orderId:$route.query.orderId}}" title="查看预约单">查看预约单</router-link>
              <router-link :to="{path:'/ticket'}" title="继续订票" class="cancel">继续订票</router-link>
            </p>
          </div>
          <div class="orderinfo" v-else>
            <h1>
              <i></i>
              <p>支付成功</p>
            </h1>
            <p><span class="price">{{$route.query.pri ? Number($route.query.pri/100).toFixed(2) : 0}}元</span></p>
            <p>订单号码：<em>{{$route.query.orderNumber}}</em></p>
            <p>
              <!--<router-link :to="{path:'/orderDetails',query:{orderNumber:$route.query.orderNumber,orderId:$route.query.orderId}}" title="查看订单">查看订单</router-link>-->
              <router-link :to="{path:'/order'}" title="查看订单">查看订单</router-link>
              <router-link :to="{path:'/ticketQP'}" title="继续订票" class="cancel">继续订票</router-link>
            </p>
          </div>
          <div class="tips">
            <h1><i></i>温馨提示</h1>
            <ul>
              <p>预定时间：全天可售，可预定当日门票（08：30-16：30期间取票）。</p>
              <p>取票方式：凭借购票二维码，扫码入园或窗口取票。</p>
              <p>退款规程：只要未使用都可申请。退款不收取手续费，不支持部分退款，一经取票，不退不换。</p>
              <p>【成人票】身高1.5m以上成人 </p>
              <p>【亲子票】1名成人+1名儿童（1.2米≤儿童身高≤1.5米） </p>
              <p>【家庭票】2名成人+1名儿童（1.2米≤儿童身高≤1.5米） </p>
              <p>【优待票】1.儿童：1.2米≤身高≤1.5米 2.长者：≥60周岁（凭有效身份证件）3.残障人士、现役军人（凭有效身份证件）  </p>
              <p>【免票】身高1.2米以下小朋友和70周岁以上老人（凭有效身份证件）付费项目除外，入园需要购票成人陪同，每名购票成人仅可携带一名免票人员（成人标准：身高1.5米以上）</p>
              <p>如有疑问请联系我们的客服人员：4006615666 </p>
            </ul>
            <div>如有疑问请联系我们的客服人员：<em>400-8196-333</em></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  export default {
    name: "paySuccess",
    data() {
      return {

      };
    },
    methods: {

    }
  };
</script>

<style scoped>
  @import "../assets/css/step.css";
  @import "../assets/css/paySuccess.css";
</style>
